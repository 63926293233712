import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { getNextPage, getPrevPage } from '../navUtils';
import { useBreakpoints } from './useMediaQuery';

const TIMEOUT = 250

export function useSwipeNavigation() {
  const [canNav, setCanNav] = useState(false)
  const [pageLocked, setPageLocked] = useState(false)
  const breakpoints = useBreakpoints()
  const timerRef = useRef

  const location = useLocation()
  const navigate = useNavigate()

  const handleNext = useCallback(() => {
    if (canNav && !pageLocked) {
      setCanNav(false)

      if (location.pathname === '/') {
        navigate(location.pathname, { state: 'prepare' })
      } else if (location.pathname === '/entertainment/1' && breakpoints.lg) {
        navigate('/social')
      } else {
        navigate(getNextPage(location))
      }
    }
  }, [breakpoints.lg, canNav, location, navigate, pageLocked])

  const handlePrev = useCallback(() => {
    if (canNav && !pageLocked) {
      setCanNav(false)
      
      if (location.pathname === '/') {
        // do nothing
      } else if (location.pathname === '/who-we-are/1') {
        setTimeout(() => {
          navigate('/')
        }, 750)
      } else if (location.pathname === '/social' && breakpoints.lg) {
        navigate('/entertainment/1', { state: 'back' })
      } else {
        navigate(getPrevPage(location), { state: 'back' })
      }
    }
  }, [breakpoints.lg, canNav, location, navigate, pageLocked])

  const { ref } = useSwipeable({
    onSwipedDown: handlePrev,
    onSwipedUp: handleNext,
  })

  useEffect(() => {
    ref(document)

    const handleWheel = (e) => {
      if (Math.abs(e.wheelDelta) > 100) {
        if (!timerRef.current) {
          if (e.wheelDelta > 0) {
            handlePrev()
          } else if (e.wheelDelta < 0) {
            handleNext()
          }
        }
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          timerRef.current = undefined
        }, TIMEOUT)
      }
    }

    document.addEventListener('wheel', handleWheel)
    
    return () => {
      document.removeEventListener('wheel', handleWheel)
    }
  }, [handleNext, handlePrev, ref, timerRef])

  useEffect(() => {
    if (
      ['/contact-us', '/success'].includes(location.pathname) ||
      location.pathname.includes('/projects')
    ) {
      setPageLocked(true)
    } else {
      setPageLocked(false)
    }
  }, [location.pathname])

  useEffect(() => {
    setTimeout(() => {
      setCanNav(true)
    }, 500)
  }, [location.pathname])
}
