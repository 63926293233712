import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shouldFadeOutBalls: false,
  shouldReturnToLanding: false,
}

export const backgroundSlice = createSlice({
  name: 'background',
  initialState,
  reducers: {
    fadeOutBalls(state) {
      state.shouldFadeOutBalls = true
    },
    returnToLanding(state) {
      state.shouldReturnToLanding = true
    },
    resetPrompts(state) {
      for (const key of Object.keys(initialState)) {
        state[key] = initialState[key]
      }
    }
  },
})

export const {
  fadeOutBalls,
  returnToLanding,
  resetPrompts,
} = backgroundSlice.actions

export default backgroundSlice.reducer
