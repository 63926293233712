import { animated, config, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph2 from "../../../components/Paragraph2"

import video1 from './assets/1.mp4'
import video2 from './assets/2.mp4'

function Education({ position }) {
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { opacity: 1 },
  }))

  useEffect(() => {
    if (position === 0) {
      [api1, api2].forEach((api, index) => {
        api.start({ transform: 'scale(1)', delay: 250 * index })
      })
      api3.start({ opacity: 1, delay: 600, config: config.slow })
      api4.start({ opacity: 1 })

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api4.start({ opacity: 0 })
    } else if (position === 1) {
      [api1, api2].forEach((api) => {
        api.start({ transform: 'scale(0)' })
      })
      api3.start({ opacity: 0 })
      api4.start({ opacity: 0 })
    }
  }, [api1, api2, api3, api4, position, setTheme])

  return (
    <animated.div className={classNames('education', { notouch: position !== 0 })} style={spring4}>
      <div className="education-inner">
        <div className="media">
          <animated.div className="video-border vb1" style={spring1}>
            <video src={video1} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
          </animated.div>
          <animated.div className="video-border vb2" style={spring2}>
            <video src={video2} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
          </animated.div>
        </div>
      </div>
      <animated.div className="shade" style={spring3}></animated.div>
      <animated.div className="text" style={spring3}>
        <Paragraph2>
          To celebrate Black History Month: Health and Wellness, we created a dynamic AR portal experience that lets users everywhere travel to a museum and learn about Black history.
        </Paragraph2>
      </animated.div>
    </animated.div>
  )
}

export default Education
