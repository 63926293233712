import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video from './assets/1.mp4'

function Experiences1({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { top: '-100%' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { top: '0', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      api1.start({ opacity: 1, top: '0' })
      api2.start({ opacity: 1, top: '0' })
      api3.start({ opacity: 1, top: '0' })

      setTheme(breakpoints.lg ? THEMES.dark : THEMES.darkBgDarkPill)
    } else if (position === -1) {
      api3.start({ opacity: 0, top: '-100%' })
    } else if (position === 1) {
      api1.start({ opacity: 0, top: breakpoints.lg ? '-100%' : '100%' })
      api2.start({ opacity: 0, top: breakpoints.lg ? '100%' : '-100%' })
    }
  }, [api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('experiences-1', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring2}>
        <video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
      </animated.div>
      <animated.div className="text" style={spring1}>
        <Paragraph1>
          And in a partnership so nice we did it twice, we used <span className="blue">360° images</span> combined with <span className="blue">AR portals</span> to transport Mastercard<span className="tm">TM</span> cardholders to the Miami Design District in a <span className="blue">#Priceless</span> experience.
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default Experiences1
