import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph2 from "../../../components/Paragraph2"

import video from './assets/1.mp4'

function MachineLearning({ position }) {
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { opacity: 0, backgroundColor: 'transparent' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      api1.start({ opacity: 1, backgroundColor: 'black' })
      api2.start({ opacity: 1, delay: 300 })
      api3.start({ opacity: 1, delay: 600 })

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api1.start({ opacity: 0 })
    } else if (position === 1) {
      api1.start({ opacity: 0, backgroundColor: 'transparent' })
      api2.start({ opacity: 0 })
      api3.start({ opacity: 0 })
    }
  }, [api1, api2, api3, position, setTheme])

  return (
    <animated.div className={classNames('machine-learning', { notouch: position !== 0 })} style={spring1}>
      <animated.video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback style={spring2} />
      <animated.div className="text" style={spring3}>
        <Paragraph2>
          We created a pipeline that uses a single photo to create <span className="green">realistic, personalized 3D avatars of users</span>.
        </Paragraph2>
      </animated.div>
    </animated.div>
  )
}

export default MachineLearning
