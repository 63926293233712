import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useBreakpoints } from "../../utils/hooks"
import classNames from "classnames"
import { getProjectBySlug } from "../../utils/projectsUtils"
import PipeDrive from "../../utils/PipeDrive"
import Paragraph2 from "../Paragraph2"

import './index.scss'

const MAX_COUNT = 115

function ContactForm({ additionalInfo, submit = () => {} }) {
  const [enabled, setEnabled] = useState(false)
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [titleValue, setTitleValue] = useState('')
  const [companyValue, setCompanyValue] = useState('')
  const [infoValue, setInfoValue] = useState('')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [titleError, setTitleError] = useState(false)
  const [companyError, setCompanyError] = useState(false)
  const [infoError, setInfoError] = useState(false)
  const breakpoints = useBreakpoints()
  const params = useParams()
  const navigate = useNavigate()

  const handleNameValue = (e) => {
    setEnabled(true)
    setNameError(false)
    setNameValue(e.target.value)
  }

  const handleEmailValue = (e) => {
    setEnabled(true)
    setEmailError(false)
    setEmailValue(e.target.value)
  }

  const handleTitleValue = (e) => {
    setEnabled(true)
    setTitleError(false)
    setTitleValue(e.target.value)
  }

  const handleCompanyValue = (e) => {
    setEnabled(true)
    setCompanyError(false)
    setCompanyValue(e.target.value)
  }

  const handleInfoValue = (e) => {
    setEnabled(true)
    setInfoError(false)
    const newInput = e.target.value

    if (newInput.length <= MAX_COUNT) {
      setInfoValue(e.target.value)
    }
  }

  const handleSubmit = () => {
    let hasError = false

    if (!nameValue) {
      setNameError(true)
      hasError = true
    }
    if (!emailValue || !emailValue.includes('@')) {
      setEmailError(true)
      hasError = true
    }
    if (!titleValue) {
      setTitleError(true)
      hasError = true
    }
    if (!companyValue) {
      setCompanyError(true)
      hasError = true
    }
    if (additionalInfo && !infoValue) {
      setInfoError(true)
      hasError = true
    }

    if (!hasError) {
      PipeDrive.addLead({
        name: nameValue,
        email: emailValue,
        title: titleValue,
        company: companyValue,
        additionalInfo: infoValue,
        source: getProjectBySlug(params.projectSlug)?.name,
      })
      submit()
      navigate('/success')
    }
  }

  return (
    <div className="contact-form" onSubmit={handleSubmit}>
      <div className="form-item">
        <label>
          <Paragraph2>
            Name
          </Paragraph2>
          <input className={classNames({ red: nameError })} placeholder="ex. Sam Bankman-Fried" value={nameValue} onChange={handleNameValue} spellCheck={false} />
          <div className={classNames('error', { active: nameError })}>Missing name</div>
        </label>
      </div>
      <div className="form-item">
        <label>
          <Paragraph2>
            Email
          </Paragraph2>
          <input className={classNames({ red: emailError })} placeholder="Example@gmail.com" value={emailValue} onChange={handleEmailValue} spellCheck={false} />
          <div className={classNames('error', { active: emailError })}>Invalid email</div>
        </label>
      </div>
      <div className="form-item">
        <label>
          <Paragraph2>
            Job Title
          </Paragraph2>
          <input className={classNames({ red: titleError })} placeholder="ex. Chief Awesome Officer" value={titleValue} onChange={handleTitleValue} spellCheck={false} />
          <div className={classNames('error', { active: titleError })}>Missing job title</div>
        </label>
      </div>
      <div className="form-item">
        <label>
          <Paragraph2>
            Company
          </Paragraph2>
          <input className={classNames({ red: companyError })} placeholder="ex. ROSE" value={companyValue} onChange={handleCompanyValue} spellCheck={false} />
          <div className={classNames('error', { active: companyError })}>Missing company</div>
        </label>
      </div>
      {additionalInfo && (
        <div className="form-item additional-info">
          <label>
            <Paragraph2>
              Additional Info
            </Paragraph2>
            <textarea
              className={classNames({ red: infoError })}
              onChange={handleInfoValue}
              maxLength={MAX_COUNT}
              placeholder="ex. We are looking to create an AR experience for concertgoers."
              rows={breakpoints.xs ? 2 : 3}
              value={infoValue}
              spellCheck={false}
            />
            <div className={classNames('count', { red: infoValue.length >= 115 })}>{`${infoValue.length}/115 characters`}</div>
            <div className={classNames('error', { active: infoError })}>Missing description</div>
          </label>
        </div>
      )}
      <button className={classNames('cta', { enabled })} onClick={handleSubmit}>
        <Paragraph2>
          Submit
        </Paragraph2>
      </button>
    </div>
  )
}

export default ContactForm
