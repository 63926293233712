import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    theme: 'dark',
  },
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload
    },
  },
})

export const {
  setTheme,
} = uiSlice.actions

export default uiSlice.reducer
