import { Euler } from 'three'

function degreesToRadians(deg) {
  return deg * (Math.PI / 180)
}

function generateRandomRotation() {
  const randomX = (Math.random() * 60) - 30
  const randomZ = (Math.random() * 40) - 20

  return new Euler(
    degreesToRadians(randomX),
    0,
    degreesToRadians(randomZ),
  )
}

export default generateRandomRotation
