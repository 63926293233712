import Background from '../Background';
import { Outlet } from 'react-router-dom';
import SwipePill from '../SwipePill'

import './index.scss'

function Page({ style }) {
  return (
    <div className="page" style={style}>
      <Background />
      <Outlet />
      <SwipePill />
    </div>
  )
}

export default Page
