import { animated, config, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import gif1 from './assets/gif1.gif'
import video1 from './assets/video1.mp4'
import video2 from './assets/video2.mp4'

function Entertainment1({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring, api] = useSpring(() => ({
    from: { top: '100%', opacity: 0 },
  }))
  const [spring1, api1] = useSpring(() => ({
    from: { top: '-100%', opacity: 0 },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { left: '-100%', opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { top: '100%', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      if (breakpoints.lg) {
        api1.start({ opacity: 1, top: '20%', delay: 300 })
        api2.start({ opacity: 1, left: '15%', delay: 600 })
        api3.start({ opacity: 1, top: '20%', config: config.slow, delay: 900 })
      } else {
        api1.set({ opacity: 1, top: '0' })
        api2.set({ opacity: 1, left: '0' })
        api3.set({ opacity: 1, top: '0' })
      }
      api.start({ opacity: 1, top: '0' })

      setTheme(breakpoints.lg ? THEMES.light : THEMES.lightBgLightPill)
    } else if (position === -1) {
      api.start({ opacity: 0, top: '-100%' })
    } else if (position === 1) {
      api.start({ opacity: 0, top: '100%' })

      if (breakpoints.lg) {
        api1.set({ opacity: 0, top: '-100%' })
        api2.set({ opacity: 0, left: '-100%' })
        api3.set({ opacity: 0, top: '100%' })
      }
    }
  }, [api, api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('entertainment-1', { notouch: position !== 0 })} style={spring}>
      <div className="text">
        <div className="desktop">
          <Paragraph1>
            Furthering Black stories, we created an <span className="blue">in-person experience</span> that let fans at the BET Awards insert themselves into the famous <span className="blue"><span className="italic">Martin</span> title sequence</span> celebrating the show's reunion on BET+.
          </Paragraph1>
        </div>
        <div className="mobile">
          <Paragraph1>
            Furthering Black stories, we created an <span className="blue">in-person experience</span> that let fans at the BET Awards insert themselves into the famous <span className="blue"><span className="italic">Martin</span> title sequence</span>...
          </Paragraph1>
        </div>
      </div>
      <div className="media">
        <animated.video src={video1} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback className="m1" style={spring2} />
        <animated.img src={gif1} alt="martin title sequence" className="m2" style={spring1} />
        <animated.div className="video-border m3" style={spring3}>
          <video src={video2} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        </animated.div>
      </div>
    </animated.div>
  )
}

export default Entertainment1
