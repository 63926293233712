export const PROJECTS = [
  {
    name: 'AI Texture Generation',
    summary: 'Bringing custom-designed clothing and characters to life with AI Texture Generation.',
    slug: 'ai-texture-generation',
  },
  { 
    name: 'ML Speech',
    summary: 'Speaking to users through photorealistic digital avatars that converse with ML-driven speech.',
    slug: 'ml-speech',
  },
  {
    name: 'Accessory Try-On',
    summary: 'Enhancing virtual try-on for accessories using necklace and finger tracking systems.',
    slug: 'accessory-try-on',
  },
  {
    name: 'Avatar Try-On',
    summary: 'Creating virtual fitting rooms that use customizable avatars and realistic cloth physics to add new dimensions to shopping experiences.',
    slug: 'avatar-try-on',
  },
  {
    name: 'Environment Update AR',
    summary: 'Maximizing user engagement through AR experiences that use environment awareness to update in real time.',
    slug: 'env-update-ar',
  },
  {
    name: 'AR Fashion Live',
    summary: 'Engaging influencers and buyers around the world through real-time translation of a live fashion show into AR.',
    slug: 'ar-fashion-live',
  },
]

export function getProjectBySlug(slug) {
  return PROJECTS.find((project) => project.slug === slug)
}
