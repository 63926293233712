import { animated, useSpring } from '@react-spring/web'
import { useNavigate } from 'react-router-dom'
import Paragraph2 from '../../components/Paragraph2'
import ProjectList from "../../components/ProjectList"
import ProjectsHeader from '../../components/ProjectsHeader'
import { PROJECTS } from '../../utils/projectsUtils'

import './index.scss'

function ProjectsView() {
  const navigate = useNavigate()
  const [ spring ] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }))

  const handleToContact = () => {
    navigate('/contact-us')
  }

  return (
    <animated.div className="projects" style={spring}>
      <ProjectsHeader />
      <div className="projects-inner">
        <div className="scroll-hider">
          <div className="info">
            <Paragraph2>
              Technological innovations move pretty quickly. We look forward to digging deeper into the following projects and more in the new year.
            </Paragraph2>
          </div>
          <ProjectList projects={PROJECTS} />
          <div className="footer">
            <Paragraph2>Have a project in mind?</Paragraph2>
            <button className="cta" onClick={handleToContact}>
              <Paragraph2>Contact Us</Paragraph2>
            </button>
          </div>
        </div>
      </div>
    </animated.div>
  )
}

export default ProjectsView
