import { useCallback, useEffect, useState } from "react"
import { animated, useSpring } from '@react-spring/web'
import { useLocation } from "react-router-dom"
import { THEMES, useTheme } from "../../utils/hooks"
import { getNextPage, getPrevPage } from "../../utils/navUtils"
import WhoWeAre1 from "./WhoWeAre/1"
import WhoWeAre2 from "./WhoWeAre/2"
import WhoWeAre3 from "./WhoWeAre/3"
import WhoWeAre4 from "./WhoWeAre/4"
import Fashion1 from "./Fashion/1"
import Fashion2 from "./Fashion/2"
import Impact from "./Impact"
import Gaming from "./Gaming"
import Stats from "./Stats"
import Education from "./Education"
import Entertainment1 from "./Entertainment/1"
import Entertainment2 from "./Entertainment/2"
import Social from "./Social"
import Experiences1 from "./Experiences/1"
import Experiences2 from "./Experiences/2"
import WineAndSpirits from "./WineAndSpirits.js"
import MachineLearning from "./MachineLearning"
import LookingForward from "./LookingForward"

import './index.scss'

function ContentView() {
  const [prevPage, setPrevPage] = useState('')
  const [currentPage, setCurrentPage] = useState('')
  const [nextPage, setNextPage] = useState('')
  const location = useLocation()
  const { theme } = useTheme()
  const [spring, api] = useSpring(() => ({
    from: { backgroundColor: 'transparent' },
  }))

  const renderPages = useCallback(() => {
    return [prevPage, currentPage, nextPage].map((page, index) => {
      let Component

      switch(page) {
        case '/who-we-are/1':
          Component = WhoWeAre1
          break;
        case '/who-we-are/2':
          Component = WhoWeAre2
          break;
        case '/who-we-are/3':
          Component = WhoWeAre3
          break;
        case '/who-we-are/4':
          Component = WhoWeAre4
          break;
          case '/fashion/1':
          Component = Fashion1
          break;
        case '/fashion/2':
          Component = Fashion2
          break;
        case '/impact':
          Component = Impact
          break;
        case '/gaming':
          Component = Gaming
          break;
        case '/stats':
          Component = Stats
          break;
        case '/education':
          Component = Education
          break;
        case '/entertainment/1':
          Component = Entertainment1
          break;
        case '/entertainment/2':
          Component = Entertainment2
          break;
        case '/social':
          Component = Social
          break;
        case '/experiences/1':
          Component = Experiences1
          break;
        case '/experiences/2':
          Component = Experiences2
          break;
        case '/wine-and-spirits':
          Component = WineAndSpirits
          break;
        case '/machine-learning':
          Component = MachineLearning
          break;
        case '/looking-forward':
          Component = LookingForward
          break;
        default:
          Component = () => null
          break;
      }

      const getPosition = () => {
        return index - 1
      }

      return page ? <Component key={page} position={getPosition()} /> : null
    })
  }, [prevPage, currentPage, nextPage])

  useEffect(() => {
    const newNextPage = getNextPage(location)
    const newPrevPage = getPrevPage(location)

    setPrevPage(newPrevPage)
    setCurrentPage(location.pathname)
    setNextPage(newNextPage)
  }, [location])

  useEffect(() => {
    api.start({
      to: { backgroundColor: (theme === THEMES.dark || theme === THEMES.darkBgDarkPill) ? 'transparent' : 'white' },
    })
  }, [api, theme])

  return (
    <animated.div className={`content ${theme}`} style={spring}>
      {renderPages()}
    </animated.div>
  )
}

export default ContentView
