import { useEffect, useState } from 'react';
import { useWindowDimensions, ThemeContext, THEMES } from './utils/hooks';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import background from './redux/background'
import logger from 'redux-logger'
import ui from './redux/ui'
import { Provider } from 'react-redux'
import AppRouting from './components/AppRouting';

import './App.scss';

const store = configureStore({
  reducer: {
    background,
    ui,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat([logger])
  )
})

function App() {
  const [theme, setTheme] = useState(THEMES.dark)
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    document.documentElement.style.setProperty('--windowheight', `${windowDimensions.height}px`)
    document.documentElement.style.setProperty('--windowwidth', `${windowDimensions.width}px`)
  }, [windowDimensions])

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <BrowserRouter>
          <AppRouting />
        </BrowserRouter>
      </ThemeContext.Provider>
    </Provider>
  );
}

export default App;
