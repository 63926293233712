import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES } from "../../../utils/hooks"
import { useLocation } from 'react-router'
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'
import Paragraph2 from "../../../components/Paragraph2"

import bloomingdales from './assets/bloomingdales.png'
import selfridges from './assets/selfridges.png'
import video1 from './assets/1.mp4'
import video2 from './assets/2.mp4'

function Impact({ position }) {
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { opacity: 1 },
  }))

  useEffect(() => {
    if (position === 0) {
      if (location.state !== 'back') {
        [api1, api2].forEach((api, index) => {
          api.start({
            to: { transform: 'scale(1)' },
            delay: 250 * index,
          })
        })
        api3.start({
          to: { opacity: 1 },
          delay: 500,
        })
      } else {
        [api1, api2].forEach((api) => {
          api.set({ transform: 'scale(1)' })
        })
        api3.set({ opacity: 1 })
      }
      api4.start({ opacity: 1 })
      
      setTheme(THEMES.dark)
    } else if (position === -1) {
      api4.start({ opacity: 0 })
    } else if (position === 1) {
      api1.start({ transform: 'scale(0)' })
      api2.start({ transform: 'scale(0)' })
      api3.start({ opacity: 0 })
      api4.start({ opacity: 0 })
    }
  }, [api1, api2, api3, api4, location.state, position, setTheme])

  return (
    <animated.div className={classNames('impact', { notouch: position !== 0 })} style={spring4}>
      <div className="impact-inner">
        <div className="media">
          <animated.div className="video-border vb1" style={spring2}>
            <video src={video1} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
          </animated.div>
          <animated.div className="video-border vb2" style={spring1}>
            <video src={video2} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
          </animated.div>
        </div>
        <animated.div className="text" style={spring3}>
          <div className="caption">
            <Paragraph1>
              22%
            </Paragraph1>
            <div className="mobile">
              <Paragraph2>
                Conversion
              </Paragraph2>
              <Paragraph2>
                to Shop
              </Paragraph2>
            </div>
            <div className="desktop">
              <Paragraph2>
                Conversion to Shop
              </Paragraph2>
            </div>
            <img src={bloomingdales} alt="bloomingdales" />
          </div>
          <div className="caption c2">
            <Paragraph1>
              51 sec
            </Paragraph1>
            <Paragraph2>
              Avg Engagement
            </Paragraph2>
            <img src={selfridges} alt="selfridges" />
          </div>
        </animated.div>
      </div>
    </animated.div>
  )
}

export default Impact
