import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video from './assets/1.mp4'

function Social({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { top: '-100%', opacity: 1 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { top: '0', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      api1.start({ opacity: 1, top: '0' })
      api2.start({ opacity: 1, top: '0' })
      api3.start({ opacity: 1 })

      setTheme(breakpoints.lg ? THEMES.dark : THEMES.darkBgDarkPill)
    } else if (position === -1) {
      api1.start({ opacity: 0, top: '100%' })
      api2.start({ opacity: 0, top: '-100%' })
      api3.start({ opacity: 0 })
    } else if (position === 1) {
      api1.start({ opacity: 0, top: '100%' })
      api2.start({ opacity: 0, top: '-100%' })
    }
  }, [api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('social', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring1}>
        <div className="video-border">
          <video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        </div>
      </animated.div>
      <animated.div className="text" style={spring2}>
        <Paragraph1>
          In May, we helped spread the word to <span className="blue">10 Million+</span> Snapchat users that <span className="blue">Mastercard<span className="tm">TM</span></span> cardholders could help donate to <span className="blue">Stand Up to Cancer</span> through an AR Lens that brought them into Mastercard<span className="tm">TM</span>'s TV spot.
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default Social
