export const navMap = [
  '/',
  '/who-we-are/1',
  '/who-we-are/2',
  '/who-we-are/3',
  '/who-we-are/4',
  '/fashion/1',
  '/fashion/2',
  '/impact',
  '/gaming',
  '/stats',
  '/education',
  '/entertainment/1',
  '/entertainment/2',
  '/social',
  '/experiences/1',
  '/experiences/2',
  '/wine-and-spirits',
  '/machine-learning',
  '/looking-forward',
  '/projects',
]

export function getNextPage(location) {
  const currentIndex = navMap.findIndex((url) => location.pathname === url || location.pathname === url + '/')
  
  if (typeof currentIndex !== 'undefined') {
    return navMap[currentIndex + 1]
  } else {
    return '/'
  }
}

export function getPrevPage(location) {
  const currentIndex = navMap.findIndex((url) => location.pathname === url || location.pathname === url + '/')
  
  if (typeof currentIndex !== 'undefined' && currentIndex > 0) {
    return navMap[currentIndex - 1]
  } else {
    return '/'
  }
}
