import { useEffect, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { useTheme, THEMES } from '../../../utils/hooks'
import classNames from 'classnames'

const TIMER = 500

function LookingForward({ position }) {
  const [lightUpIndex, setLightUpIndex] = useState(0)
  const { setTheme } = useTheme()
  const [spring, api] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring1, api1] = useSpring(() => ({
    from: { color: 'transparent' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { color: 'transparent' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { color: 'transparent' },
  }))

  useEffect(() => {
    if (position === 0) {

      api.start({ opacity: 1 })

      setTheme(THEMES.dark)
    } else {
      api.start({ opacity: 0 })
    }
  }, [api, api1, api2, api3, position, setTheme])

  useEffect(() => {
    switch (lightUpIndex) {
      case 0:
        api1.set({ color: "white" })
        api2.set({ color: "transparent" })
        api3.set({ color: "transparent" })
        break;
      case 1:
        api2.set({ color: "white" })
        api3.set({ color: "transparent" })
        api1.set({ color: "transparent" })
        break;
      case 2:
        api3.set({ color: "white" })
        api1.set({ color: "transparent" })
        api2.set({ color: "transparent" })
        break;
      default:
        break;
    }

    setTimeout(() => {
      setLightUpIndex((lightUpIndex + 1) % 3)
    }, TIMER)
  }, [api1, api2, api3, lightUpIndex])

  return (
    <animated.div className={classNames('looking-forward', { notouch: position !== 0 })} style={spring}>
      <div className="text">
        <animated.div className="doubleline" style={spring1}>
          <div className="looking">looking</div>
          <div className="forward">forward</div>
        </animated.div>
        <animated.div className="doubleline" style={spring2}>
          <div className="looking">looking</div>
          <div className="forward">forward</div>
        </animated.div>
        <animated.div className="doubleline" style={spring3}>
          <div className="looking">looking</div>
          <div className="forward">forward</div>
        </animated.div>
      </div>
    </animated.div>
  )
}

export default LookingForward
