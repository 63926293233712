import { useEffect } from "react"
import { animated, config, useSpring } from '@react-spring/web'
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import { useLocation } from "react-router"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video1 from './assets/1.mp4'
import video2 from './assets/2.mp4'

function Fashion1({ position }) {
  const breakpoints = useBreakpoints()
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%', left: '0' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { top: '100%', left: '0' },
    config: {
      duration: 150,
    }
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { left: '0', top: '0', opacity: 0 },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { top: '0', left: '0', zIndex: 1, opacity: 0 }
  }))

  useEffect(() => {
    if (position === 0) {
      if (breakpoints.lg) {
        if (location.state === 'back') {
          api1.set({ left: '0' })
          api2.set({ left: '0' })
          api3.start({ left: '0' })          
          api4.set({ zIndex: 1 })
          api4.start({ opacity: 1 })
        } else {
          api1.set({ top: '0' })
          api2.set({ top: '0' })
          api1.start({ left: '0' })
          api2.start({ left: '0' })
          api3.set({ top: '0' })
          api4.start({ opacity: 1, top: '47.5%', config: config.slow })
        }
      } else {
        if (location.state === 'back') {
          api1.set({ left: '0', top: '0' })
          api2.set({ left: '0', top: '0' })
          api3.start({ top: '0' })
        } else {
          api1.start({ top: '0' })
          api2.start({ top: '0' })          
          api3.start({ top: '0' })
        }
      }
      api3.start({ opacity: 1 })

      setTheme(THEMES.lightBgLightPill)
    } else if (position === -1) {
      if (breakpoints.lg) {
        api1.set({ left: '0', top: '0' })
        api2.set({ left: '0', top: '0' })
        api4.set({ top: '47.5%' })
        api4.start({
          to: [
            { opacity: 0 },
            { zIndex: 0 },
          ]
        })
      }
      api3.start({ opacity: 0 })
    } else if (position === 1) {
      if (breakpoints.lg) {
        api1.set({ left: '100%', top: '0' })
        api2.set({ left: '100%', top: '0' })
        api4.set({ top: '-100%' })
      } else {
        api1.set({ top: '100%' })
        api2.set({ top: '100%' })
      }
    }
  }, [api1, api2, api3, api4, breakpoints, location.state, position, setTheme])

  return (
    <animated.div className={classNames('fashion-1', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring2}>
        <video src={video1} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback className="v1" />
        <video src={video2} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback className="v2" />
        <animated.div className="video-border v1 desktop" style={spring4}>
          <video src={video1} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        </animated.div>
      </animated.div>
      <animated.div className="text" style={spring1}>
        <Paragraph1>
          We changed the way fashion lovers experience the latest styles using green screen video and AR with <span className="blue">Bloomingdale's</span>...
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default Fashion1
