import './index.scss'

function Subheading({ children }) {
  return (
    <h2 className="subheading">
      {children}
    </h2>
  )
}

export default Subheading
