import './index.scss'

function Heading() {
  return (
    <h1 className="heading">
      <div className="marquee">
        <span className="spacer">
          WRA
        </span>
        <span className="wrapped w1">
          WRAPPED
        </span>
      </div>
      <div className="marquee">
        <span className="spacer">
          APP
        </span>
        <span className="wrapped w2">
          WRAPPED
        </span>
      </div>
      <div className="marquee">
        <span className="spacer">
          ED
        </span>
        <span className="wrapped w3">
          WRAPPED
        </span>
      </div>
    </h1>
  )
}

export default Heading
