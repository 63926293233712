import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'
import Paragraph2 from '../../../components/Paragraph2'

import arrow from './assets/arrow.gif'
import arrowDesktop from './assets/arrowdesktop.gif'
import barbrun from './assets/barbrun.gif'
import barbyears from './assets/barbyears.png'

function Stats({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring, api] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring1, api1] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      [api1, api2, api3].forEach((api, index) => {
        api.start({ opacity: 1, delay: 225 * (index + 1) })
      })
      api.start({ opacity: 1 })

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api.start({ opacity: 0 })
    } else if (position === 1) {
      [api1, api2, api3].forEach((api) => {
        api.start({ opacity: 0 })
      })
      api.start({ opacity: 0 })
    }
  }, [api, api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('stats', { notouch: position !== 0 })} style={spring}>
      <div className="stats-inner">
        <animated.div className="barbs" style={spring1}>
          <Paragraph1>3 Game Styles</Paragraph1>
          <div className="barb-wrapper">
            <img src={barbyears} alt="different style barbarians" />
          </div>
        </animated.div>
        <animated.div className="barbs" style={spring2}>
          <Paragraph1>900+ Assets Created</Paragraph1>
          <div className="run-wrapper">
            <img src={barbrun} alt="barbarian running" />
          </div>
        </animated.div>
        <animated.div className="barbs" style={spring3}>
          <Paragraph1>In just 4 months</Paragraph1>
          <Paragraph2>
            Real-time footage of us sprinting
            <span className="rainbow-wrapper">
              <img src={breakpoints.lg ? arrowDesktop : arrow} alt="rainbow arrow" />
            </span>
            <br></br>
            towards the finish line
          </Paragraph2>
        </animated.div>
      </div>
    </animated.div>
  )
}

export default Stats
