import { useEffect } from "react"
import { animated, useSpring } from '@react-spring/web'
import { useParams } from "react-router-dom"
import { THEMES, useTheme } from '../../utils/hooks'
import ContactForm from "../../components/ContactForm"
import Paragraph1 from '../../components/Paragraph1'
import Paragraph2 from "../../components/Paragraph2"
import ProjectsHeader from '../../components/ProjectsHeader'
import { getProjectBySlug } from '../../utils/projectsUtils'

import './index.scss'

function ProjectDetailView() {
  const params = useParams()
  const { setTheme } = useTheme()
  const project = getProjectBySlug(params.projectSlug)
  const [spring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }))

  useEffect(() => {
    setTheme(THEMES.dark)
  }, [setTheme])

  return (
    <animated.div className="project-detail" style={spring}>
      <ProjectsHeader />
      <div className="top-text">
        <Paragraph2>Interested in:</Paragraph2>
        <Paragraph1>
          {project.summary}
        </Paragraph1>
        <div className="drop-us">
          Drop us a line using the form below.
        </div>
        <ContactForm />
      </div>
    </animated.div>
  )
}

export default ProjectDetailView
