import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video2 from './assets/video2.mp4'

function Entertainment2({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      api1.start({ top: '0' })
      api2.start({ opacity: 1, delay: 500 })
      api3.start({ opacity: 1 })

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api3.start({ opacity: 0 })
    } else if (position === 1) {
      api1.start({ top: '100%' })
      api2.start({ opacity: 0 })
      api3.start({ opacity: 0 })
    }
  }, [api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('entertainment-2', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring1}>
        <video src={video2} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        <animated.div className="text" style={spring2}>
          <Paragraph1>
            ...celebrating the show's reunion on BET+.
          </Paragraph1>
        </animated.div>
      </animated.div>
    </animated.div>
  )
}

export default Entertainment2
