import { useEffect } from "react"
import { animated, useSpring } from '@react-spring/web'
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

function WhoWeAre2({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [ spring, api ] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      api.start({
        to: { opacity: 1 },
        delay: 500,
      })
      setTheme(THEMES.dark)
    } else if (position === 1) {
      api.start({
        to: { opacity: 0 },
      })
    } else if (position === -1) {
      api.start({
        to: { opacity: 0 }
      })
    }
  }, [api, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('who-we-are-2', { notouch: position !== 0 })} style={spring}>
      <div className="text">
        <Paragraph1>
          ROSE is a Black-owned digital agency pushing the boundaries of creative technology.
        </Paragraph1>
      </div>
    </animated.div>
  )
}

export default WhoWeAre2
