import { useEffect } from "react"
import { animated, config, useSpring } from '@react-spring/web'
import { useTheme, THEMES } from "../../../utils/hooks"
import { useLocation } from "react-router"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import screenshot1 from './assets/1.png'
import screenshot2 from './assets/2.png'
import screenshot3 from './assets/3.png'
import screenshot4 from './assets/4.png'

function WhoWeAre3({ position }) {
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { transform: 'scale(0)' },
  }))
  const [spring5, api5] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring6, api6] = useSpring(() => ({
    from: { opacity: 0, top: '0%' },
  }))

  useEffect(() => {
    if (position === 0) {
      setTheme(THEMES.light)

      if (location.state === 'back') {
        [api1, api2, api3, api4].forEach((api) => {
          api.set({ transform: 'scale(1)' })
        })
        api5.set({ top: '0' })
        api6.set({ opacity: 1, top: '-100%' })

        api6.start({
          to: { top: '0' },
        })
      } else {
        [api1, api2, api3, api4].forEach((api, index) => {
          api.set({ transform: 'scale(0)' })
          api.start({
            from: { transform: 'scale(0)' },
            to: { transform: 'scale(1)' },
            delay: 250 * (index + 1),
          })
        })
  
        api5.start({
          from: { top: '100%' },
          to: { top: '0%' },
          config: config.slow,
        })
        api6.set({ opacity: 1, top: '0%' })
      }
    } else if (position === 1) {
      [api1, api2, api3, api4].forEach((api, index) => {
        api.set({ transform: 'scale(0)' })
      })
      api6.set({ opacity: 0 })
    } else if (position === -1) {
      api6.start({
        from: { opacity: 1 },
        to: { opacity: 0 },
      })
    }
  }, [api1, api2, api3, api4, api5, api6, location.state, position, setTheme])

  return (
    <animated.div className={classNames('who-we-are-3', { notouch: position !== 0 })} style={spring6}>
      <div className="media">
        <animated.img
          alt="tailor-made AI visual generation"
          src={screenshot1}
          style={spring1}
        />
        <animated.img
          src={screenshot2}
          alt="Dall-E 2"
          style={spring2}
        />
        <animated.img
          src={screenshot3}
          alt="Unreal Engine MetaHuman"
          style={spring3}
        />
        <animated.img
          src={screenshot4}
          alt="8th Wall Lightship VPS for Web"
          style={spring4}
        />
      </div>
      <animated.div className="text" style={spring5}>
        <Paragraph1>
          We spend our time keeping up with the latest in <span className="blue">AR, VR, XR, AI, & ML</span> and helping our clients apply these technologies to their businesses.
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default WhoWeAre3
