import { useEffect } from "react"
import { animated, useSpring } from '@react-spring/web'
import { useTheme, THEMES } from "../../../utils/hooks"
import classNames from 'classnames'
import Subheading from "../../../components/Subheading"

function WhoWeAre1({ position }) {
  const { setTheme } = useTheme()
  const [ spring1, api1 ] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [ spring2, api2 ] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [ spring3, api3 ] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [ spring4, api4 ] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      [api1, api2, api3, api4].forEach((api, index) => {
        api.start({
          from: { opacity: 0 },
          to: { opacity: 1 },
          delay: 500 * index,
        })
      })
      setTheme(THEMES.dark)
    } else if (position === -1) {
      [api1, api2, api3, api4].forEach((api) => {
        api.start({
          to: { opacity: 0 },
        })
      })      
    }
  }, [api1, api2, api3, api4, position, setTheme])

  return (
    <animated.div className={classNames('who-we-are-1', { notouch: position !== 0 })} style={spring1}>
      <div className="mobile">
        <Subheading>In</Subheading>
        <Subheading>case</Subheading>
        <Subheading>you</Subheading>
        <Subheading>didn’t</Subheading>
        <Subheading>know</Subheading>
        <Subheading>
          <animated.span className="period" style={spring2}>. </animated.span>
          <animated.span className="period" style={spring3}>. </animated.span>
          <animated.span className="period" style={spring4}>. </animated.span>
        </Subheading>
      </div>
      <div className="desktop">
        <Subheading>
          In case you<br></br>didn't know
          <animated.span className="period" style={spring2}>. </animated.span>
          <animated.span className="period" style={spring3}>. </animated.span>
          <animated.span className="period" style={spring4}>. </animated.span>
        </Subheading>
      </div>
    </animated.div>
  )
}

export default WhoWeAre1
