const defaultConfig = {
  baseUrl: 'https://rosedigital.pipedrive.com/api/v1',
}

const defaultParams = {
  api_token: process.env.REACT_APP_PIPEDRIVE,
}

class PipeDrive {
  constructor(config) {
    this.config = Object.assign({}, defaultConfig, config)
  }

  constructParams(params = {}) {
    return Object.assign({}, defaultParams, params)
  }

  constructUrl(endpoint, params = {}) {
    const url = new URL(this.config.baseUrl + endpoint)
    const constructedParams = this.constructParams(params)
    url.search = new URLSearchParams(constructedParams).toString()
    return url
  }

  async findPerson(term) {
    const params = { term }

    const res = await fetch(
      this.constructUrl('/persons/search', params),
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )

    const resBody = await res.json()
    
    if (resBody.success) {
      return resBody
    } else {
      console.error(`Error finding person: ${term}`)
      console.log(resBody)
    }
  }

  async findOrganization(term) {
    const params = { term }

    const res = await fetch(
      this.constructUrl('/organizations/search', params),
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )

    const resBody = await res.json()
    
    if (resBody.success) {
      return resBody
    } else {
      console.error(`Error finding organization: ${term}`)
      console.log(resBody)
    }
  }

  async addPerson(name, fields = {}) {
    const body = { name }
    if (fields.email) {
      body.email = fields.email
    }
    if (fields.phone) {
      body.phone = fields.phone
    }
    if (fields.orgId) {
      body.org_id = fields.orgId
    }
    if (fields.title) {
      body.job_title = fields.title
    }

    const res = await fetch(
      this.constructUrl('/persons'),
      {
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    const resBody = await res.json()
    
    if (resBody.success) {
      return resBody
    } else {
      console.error(`Error adding person: ${name}`)
      console.log(resBody)
    }
  }

  async addOrganization(name) {
    const body = { name }

    const res = await fetch(
      this.constructUrl('/organizations'),
      {
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    const resBody = await res.json()
    
    if (resBody.success) {
      return resBody
    } else {
      console.error(`Error adding organization: ${name}`)
      console.log(resBody)
    }
  }

  async findOrAddPerson(name, fields) {
    const foundPersonRes = await this.findPerson(name)
    const items = foundPersonRes.data.items
    
    if (items.length) {
      if (items[0].result_score > 0.3) {
        return items[0].item.id
      }
    }

    const addedPersonRes = await this.addPerson(name, fields)
    return addedPersonRes.data.id
  }

  async findOrAddOrganization(name) {
    const foundOrgRes = await this.findOrganization(name)
    const items = foundOrgRes.data.items

    if (items.length) {
      if (items[0].result_score > 0.3) {
        return items[0].item.id
      }
    }
    
    const addedOrgRes = await this.addOrganization(name)
    return addedOrgRes.data.id
  }

  async addLead(fields) {
    const leadTitle = `ROSE Wrapped - ${fields.source || fields.additionalInfo}`
    const leadOrganization = await this.findOrAddOrganization(fields.company)
    const leadPerson = await this.findOrAddPerson(fields.name, {
      email: fields.email,
      phone: fields.phone,
      orgId: leadOrganization,
    })
    
    const leadObj = {
      title: leadTitle,
      person_id: leadPerson,
      // lead source
      '94f58896c80184659bead460c756105d414a3bb7': '22',
    }

    const res = await fetch(
      this.constructUrl('/leads'),
      {
        body: JSON.stringify(leadObj),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }
    )

    const resBody = await res.json()
    
    if (resBody.success) {
      return resBody
    } else {
      console.error(`Error adding lead!`)
      console.log(resBody)
    }
  }
}

export default new PipeDrive()
