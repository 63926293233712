import { useEffect } from "react"
import { animated, useSpring } from '@react-spring/web'
import { THEMES, useTheme } from '../../utils/hooks'
import ContactForm from "../../components/ContactForm"
import Paragraph2 from "../../components/Paragraph2"
import ProjectsHeader from '../../components/ProjectsHeader'

import './index.scss'

function ContactView() {  
  const { setTheme } = useTheme()
  const [spring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }))

  useEffect(() => {
    setTheme(THEMES.dark)
  }, [setTheme])

  return (
    <>
      <animated.div className="contact-us" style={spring}>
        <ProjectsHeader />
        <div className="top-text">
          <Paragraph2>Contact Us</Paragraph2>
          <div className="drop-us">
            Drop us a line using the form below.
          </div>
          <ContactForm additionalInfo />
        </div>
      </animated.div>
    </>
  )
}

export default ContactView
