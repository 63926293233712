import { useEffect, useState } from 'react'
import { animated, config, useSpring } from '@react-spring/web'
import { useLocation } from 'react-router-dom'
import { useTheme } from '../../utils/hooks';
import classNames from 'classnames';

import './index.scss';

function UpCaret() {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 7L7 2L12 7" stroke="white" strokeWidth="2" strokeLinecap="square"/>
    </svg>
  )
}

function DownCaret() {
  return (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2L7 7L2 2" stroke="white" strokeWidth="2" strokeLinecap="square"/>
    </svg>
  )
}

function SpringChar({ char, index }) {
  const [spring, api] = useSpring(() => ({
    from: { bottom: '0px' },
  }))

  useEffect(() => {
    const startAnim = () => {
      api.start({
        from: { bottom: '0px' },
        to: async (next) => {
          await next({ bottom: '3px', config: { tension: 400, friction: 10 } })
          await next({ bottom: '0px', config: { tension: 400, friction: 10 } })
        },
        delay: (index + 2) * 65,
      })
    }
    const interval = setInterval(startAnim, 3000)
    startAnim()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [api, index])

  return (
    <animated.span className="char" style={spring}>{char}</animated.span>
  )
}

function SwipePill() {
  const [active, setActive] = useState(true)
  const location = useLocation()
  const { theme } = useTheme()
  const [spring, api] = useSpring(() => ({
    from: { opacity: 1 },
  }))
  const [spring1, api1] = useSpring(() => ({
    from: { bottom: '0px' },
  }))

  const renderText = (text) => (
    Array.from(text).map((char, index) => (
      <SpringChar char={char} key={index} index={index} />
    ))
  )

  useEffect(() => {
    if (location.pathname === '/projects') {
      api.start({
        to: { opacity: 0 },
        config: config.stiff,
        onRest() {
          setActive(false)
        }
      })
    } else if (
      location.pathname.includes('/projects') ||
      location.pathname === '/contact-us' ||
      location.pathname === '/success'
    ) {
      setActive(false)
      api.set({ opacity: 0 })
    } else {
      setActive(true)
      api.start({
        to: { opacity: 1 },
      })
    }
  }, [api, location])
  
  useEffect(() => {
    const startAnim = () => {
      api1.start({
        from: { bottom: '0px' },
        to: async (next) => {
          await next({ bottom: '3px', config: { tension: 400, friction: 10 } })
          await next({ bottom: '0px', config: { tension: 400, friction: 10 } })
        },
      })
    }
    const interval = setInterval(startAnim, 3000)
    startAnim()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [api1])

  return (
    <animated.div className={classNames('swipe-pill', theme, { active })} style={spring}>
      <div className="blur"></div>
      <div className="pill-inner">
        <div className="mobile">
          <animated.span className="char" style={spring1}>
            <UpCaret />
          </animated.span>
          <span className="char space">&nbsp;</span>
          {renderText("Swipe up")}
        </div>
        <div className="desktop">
          <animated.span className="char" style={spring1}>
            <DownCaret />
          </animated.span>
          <span className="char space">&nbsp;</span>
          {renderText("Scroll down")}
        </div>
      </div>
    </animated.div>
  )
}

export default SwipePill
