import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import { useLocation } from 'react-router'
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video3 from './assets/3.mp4'

function Fashion2({ position }) {
  const breakpoints = useBreakpoints()
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%', transform: 'scale(1)' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0, backgroundColor: 'transparent' },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { transform: 'scale(0)' }
  }))

  useEffect(() => {
    if (position === 0) {
      if (breakpoints.lg) {
        if (location.state === 'back') {
          api1.set({ top: '0' })
          api2.set({ opacity: 1 })
          api4.set({ transform: 'scale(1)' })
        } else {
          api1.start({ top: '0' })
          api2.start({ opacity: 1 })
          api4.start({ transform: 'scale(1)', delay: 500 })
        }
        
        api3.start({ backgroundColor: 'white', opacity: 1 })
      } else {
        if (location.state === 'back') {
          api1.set({ top: '0' })
          api2.set({ opacity: 1 })
          api4.set({ transform: 'scale(1)' })
          api3.start({ opacity: 1 })
        } else {
          api1.set({ top: '0' })
          api2.start({ opacity: 1, delay: 500 })
          api3.start({ opacity: 1, delay: 250 })
          api4.start({ transform: 'scale(1)', delay: 250 })
        }
      }

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api1.set({ top: '0' })
      api2.start({ opacity: 0 })
      api3.start({ opacity: 0 })
    } else if (position === 1) {
      api1.set({ top: '100%' })
      api2.start({ opacity: 0 })
      api3.start({ opacity: 0 })
      api4.set({ transform: 'scale(0)' })
    }
  }, [api1, api2, api3, api4, breakpoints, location.state, position, setTheme])

  return (
    <animated.div className={classNames('fashion-2', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring1}>
        <animated.div className="video-border" style={spring4}>
          <video src={video3} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        </animated.div>
        <animated.div className="text" style={spring2}>
          <Paragraph1>
            ...and took it international with <span className="desktop">Selfridges</span>.
          </Paragraph1>
        </animated.div>
      </animated.div>
    </animated.div>
  )
}

export default Fashion2
