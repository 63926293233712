import { useEffect, useState } from 'react'

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
export const useMediaQuery = (mediaQuery) => {
  const [isVerified, setIsVerified] = useState(!!window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler)
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler)
    }

    documentChangeHandler()
    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler)
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(documentChangeHandler)
      }
    }
  }, [mediaQuery])

  return isVerified
}

export const useBreakpoints = () => {
  const xs = useMediaQuery('(max-width: 376px)')
  const sm = useMediaQuery('(min-width: 377px) and (max-width: 767px)')
  const md = useMediaQuery('(min-width: 768px) and (max-width: 1024px)')
  const lg = useMediaQuery('(min-width: 1025px)')

  return {
    xs,
    sm,
    md,
    lg,
    size: xs ? 'xs' : sm ? 'sm' : md ? 'md' : lg ? 'lg' : null
  }
}
