import { animated, config, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import { useLocation } from 'react-router'
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video from './assets/video.mp4'

function Gaming({ position }) {
  const breakpoints = useBreakpoints()
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { top: '100%', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      if (location.state === 'back') {
        [api1, api2, api3].forEach((api) => {
          api.set({ top: '0' })
        })
        api3.start({ opacity: 1 })
      } else {
        api1.start({ top: '0' })
        api2.start({ top: '0', config: breakpoints.lg ? config.default : { duration: 150 } })
        api3.set({ top: '0' })
        api3.start({ opacity: 1 })
      }

      setTheme(THEMES.dark)
    } else if (position === -1) {
      if (breakpoints.lg) {
        api1.start({ top: '-100%' })
        api2.start({ top: '100%' })
        api3.start({ opacity: 0 })
      } else {
        api3.start({ top: '-100%', opacity: 0 })
      }
    } else if (position === 1) {
      api1.set({ top: breakpoints.lg ? '-100%' : '100%' })
      api2.set({ top: '100%' })
    }
  }, [api1, api2, api3, breakpoints, location.state, position, setTheme])

  return (
    <animated.div className={classNames('gaming', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring2}>
        <video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
      </animated.div>
      <animated.div className="text" style={spring1}>
        <Paragraph1>
          We brought <span className="blue">170 Million</span> players of <span className="blue">Clash of Clans</span> back in time for the game's 10-year anniversary by creating a web-based game playable in a native app.
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default Gaming
