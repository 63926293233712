import { useEffect } from "react";
import { animated, useSpring } from '@react-spring/web'
import { THEMES, useTheme } from "../../utils/hooks";
import Paragraph1 from "../../components/Paragraph1";
import Paragraph2 from "../../components/Paragraph2";
import ProjectsHeader from "../../components/ProjectsHeader";

import facebook from './assets/facebook.png'
import twitter from './assets/twitter.png'
import linkedin from './assets/linkedin.png'
import instagram from './assets/instagram.png'
import medium from './assets/medium.png'
import './index.scss'

function SuccessView() {
  const { setTheme } = useTheme()
  const [spring] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }))

  useEffect(() => {
    setTheme(THEMES.dark)
  }, [setTheme])

  return (
    <animated.div className="success" style={spring}>
      <ProjectsHeader />
      <div className="success-inner">
        <Paragraph1>Success!</Paragraph1>
        <Paragraph2>Someone from our team will reach out to you soon!</Paragraph2>
        <Paragraph2>In the meantime, you can check out our <a href="https://builtbyrose.co" target="__blank" rel="noopener noreferrer">website</a> and follow us on social for more information.</Paragraph2>
        <div className="socials">
          <a className="social" href="https://www.facebook.com/rosedigital.co" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook" />
          </a>
          <a className="social" href="https://twitter.com/rosedigitalco" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="twitter" />
          </a>
          <a className="social" href="https://www.linkedin.com/company/rosedigitalco" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="linkedin" />
          </a>
          <a className="social" href="http://instagram.com/rosedigitalco/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram" />
          </a>
          <a className="social" href="https://medium.com/rose-digital" target="_blank" rel="noopener noreferrer">
            <img src={medium} alt="medium" />
          </a>
        </div>
      </div>
    </animated.div>
  )
}

export default SuccessView
