import { animated, config, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES } from "../../../utils/hooks"
import classNames from 'classnames'

import video from './assets/2.mp4'
import bg0 from './assets/0.jpg'
import bg1 from './assets/1.jpg'
import bg2 from './assets/2.jpg'
import bg3 from './assets/3.jpg'
import bg4 from './assets/4.jpg'
import bg5 from './assets/5.jpg'

function Experiences2({ position }) {
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { top: '100%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { opacity: 0, top: '100%' },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { opacity: 1 },
  }))

  useEffect(() => {
    if (position === 0) {
      api1.start({ top: '0', delay: 500, config: config.slow })
      api2.start({ opacity: 1, delay: 500 })
      api3.start({ opacity: 1, top: '0' })

      setTheme(THEMES.dark)
    } else if (position === -1) {
      api3.start({ opacity: 0 })
    } else if (position === 1) {
      api1.start({ top: '100%' })
      api2.start({ opacity: 0 })
      api3.start({ opacity: 0, top: '100%' })
    }
  }, [api1, api2, api3, api4, position, setTheme])

  return (
    <animated.div className={classNames('experiences-2', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="backdrop" style={spring4}>
        <img src={bg0} alt="background tile" />
        <img src={bg1} alt="background tile" />
        <img src={bg2} alt="background tile" />
        <img src={bg3} alt="background tile" />
        <img src={bg4} alt="background tile" />
        <img src={bg5} alt="background tile" />
        <animated.div className="shade" style={spring2}></animated.div>
      </animated.div>
      <animated.div className="media" style={spring1}>
        <div className="video-border">
          <video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
        </div>
      </animated.div>
    </animated.div>
  )
}

export default Experiences2
