import { useEffect } from 'react'
import { animated, config, useSpring } from '@react-spring/web'
import { useLocation } from 'react-router-dom'
import { useBreakpoints, useTheme, useWindowDimensions } from '../../utils/hooks'

import ball1 from './assets/ball1.png'
import ball2 from './assets/ball2.png'
import ball3 from './assets/ball3.png'
import './index.scss'

function Background() {
  const breakpoints = useBreakpoints()
  const dimensions = useWindowDimensions()
  const location = useLocation()
  const { theme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { left: '165%', top: '150%' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { left: '175%', top: '125%' },
  }))
  const [spring4, api4] = useSpring(() => ({
    from: { transform: 'translate(-50%, -50%) scale(1)', opacity: 1 },
  }))
  const [spring6, api6] = useSpring(() => ({
    from: { transform: 'translate(-50%, -50%) scale(1)', opacity: 1 },
  }))
  const [spring7, api7] = useSpring(() => ({
    from: { left: '75%', top: '-75%' },
  }))

  const duration = dimensions.width

  useEffect(() => {
    if (location.pathname === '/') {
      api1.start({
        from: { left: '125%', top: '125%' },
        to: async (next) => {
          await next({ left: '125%', top: '125%' })
          await next({ left: '-50%', top: '100%' })
          await next({ left: '0%', top: '0%' })
          await next({ left: '150%', top: '-50%' })
          await next({ left: '125%', top: '75%' })
          await next({ left: '125%', top: '125%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 12.5,
        },
      })
      api2.start({
        from: { left: '100%', top: '100%' },
        to: async (next) => {
          await next({ left: '100%', top: '100%' })
          await next({ left: '75%', top: '0%' })
          await next({ left: '25%', top: '-25%' })
          await next({ left: '-25%', top: '75%' })
          await next({ left: '50%', top: '125%' })
          await next({ left: '100%', top: '100%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 12.5,
        },
      })
      // api3.start({
      //   to: { left: '200%', top: '-200%' },
      // })
      api4.start({
        to: { transform: 'translate(-50%, -50%) scale(1)', opacity: 1 },
      })
      // api5.start({
      //   to: { transform: 'translate(-50%, -50%) scale(1)', opacity: 0 },
      // })
    } else if (location.pathname === '/who-we-are/1') {
      api1.start({
        from: { left: '25%', top: '0%' },
        to: async (next) => {
          await next({ left: '25%', top: '0%' })
          await next({ left: '100%', top: '25%' })
          await next({ left: '75%', top: '75%' })
          await next({ left: '25%', top: '100%' })
          await next({ left: '0%', top: '50%' })
          await next({ left: '25%', top: '0%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 10,
        },
      })
      api2.start({
        from: { left: '75%', top: '75%' },
        to: async (next) => {
          await next({ left: '75%', top: '75%' })
          await next({ left: '25%', top: '100%' })
          await next({ left: '0%', top: '0%' })
          await next({ left: '50%', top: '50%' })
          await next({ left: '100%', top: '50%' })
          await next({ left: '75%', top: '75%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 10,
        },
      })
      // api3.start({
      //   to: { left: '200%', top: '-200%' },
      // })
      api4.start({
        to: { transform: 'translate(-50%, -50%) scale(2)', opacity: 1 },
        config: config.slow,
      })
    } else if (location.pathname === '/who-we-are/2') {
      api1.stop()
      api1.start({
        to: { left: breakpoints.lg ? '-100%' : '-200%', top: '0%' },
        config: config.molasses,
        loop: false,
      })
      api2.stop()
      api2.start({
        to: { left: breakpoints.lg ? '150%' : '200%', top: '100%' },
        config: breakpoints.lg ? config.molasses : config.default,
        loop: false,
      })
      api4.start({
        to: { opacity: 0 },
        config: config.molasses,
        delay: 250,
        loop: false,
      })
    } else if ([
      '/who-we-are/3',
      '/who-we-are/4',
      '/fashion/1',
      '/fashion/2',
      '/gaming',
      '/stats',
      '/education',
      '/entertainment/1',
      '/entertainment/2',
      '/social',
      '/experiences/1',
      '/experiences/2',
      '/wine-and-spirits',
    ].includes(location.pathname)) {
      api1.start({
        to: { left: '150%', top: '125%' },
      })
      api2.stop()
      // api3.start({
      //   to: { left: '200%', top: '-200%', opacity: 0 },
      // })
      api4.start({
        to: { opacity: 0 },
      })
      // api5.start({
      //   to: { opacity: 0 },
      // })
      api6.start({
        to: { opacity: 1 },
      })
      api7.start({
        to: async (next) => {
          await next({ left: '75%', top: '-75%' })
          await next({ left: '-50%', top: '0%' })
          await next({ left: '-75%', top: '75%' })
          await next({ left: '100%', top: '150%' })
          await next({ left: '175%', top: '25%' })
          await next({ left: '75%', top: '-75%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 10,
        },
      })
    } else if (location.pathname === '/machine-learning') {
      api4.start({
        to: { opacity: 0 },
      })
      api6.start({
        to: { opacity: 1 },
      })
      api7.start({
        from: { left: '100%', top: '100%' },
        to: async (next) => {
          await next({ left: '100%', top: '100%' })
          await next({ left: '50%', top: '125%' })
          await next({ left: '-125%', top: '100%' })
          await next({ left: '0%', top: '100%' })
          await next({ left: '125%', top: '75%' })
          await next({ left: '100%', top: '100%' })
        },
        loop: true,
        config: {
          duration: dimensions.width * 10,
        },
      })
    } else if ([
      '/looking-forward',
      '/projects',
      '/contact-us',
      '/success',
    ].includes(location.pathname) || location.pathname.includes('/projects/')) {
      if (api1.current[0].idle) {
        api1.start({
          from: { left: '125%', top: '125%' },
          to: async (next) => {
            await next({ left: '125%', top: '125%' })
            await next({ left: '-50%', top: '100%' })
            await next({ left: '0%', top: '0%' })
            await next({ left: '150%', top: '-50%' })
            await next({ left: '125%', top: '75%' })
            await next({ left: '125%', top: '125%' })
          },
          loop: true,
          config: {
            duration: dimensions.width * 10,
          },
        })
      }
      if (api2.current[0].idle) {
        api2.start({
          from: { left: '100%', top: '25%' },
          to: async (next) => {
            await next({ left: '100%', top: '25%' })
            await next({ left: '75%', top: '0%' })
            await next({ left: '25%', top: '-25%' })
            await next({ left: '-25%', top: '75%' })
            await next({ left: '50%', top: '125%' })
            await next({ left: '100%', top: '25%' })
          },
          loop: true,
          config: {
            duration: dimensions.width * 10,
          },
        })
      }
      api4.start({
        to: { transform: 'translate(-50%, -50%) scale(1.2)', opacity: 1 },
      })
      api6.start({
        to: { opacity: 0 },
      })
      api7.stop()

      if (location.pathname === '/looking-forward') {
        api2.start({
          from: { left: '100%', top: '25%' },
          to: async (next) => {
            await next({ left: '100%', top: '25%' })
            await next({ left: '75%', top: '0%' })
            await next({ left: '25%', top: '-25%' })
            await next({ left: '-25%', top: '75%' })
            await next({ left: '50%', top: '125%' })
            await next({ left: '100%', top: '25%' })
          },
          loop: true,
          config: {
            duration: dimensions.width * 12.5,
          },
        })
        // api3.stop()
        // api5.start({
        //   to: { opacity: 1 },
        // })
      } else {
        api4.start({
          opacity: 0.5,
        })
      }
    }
  }, [api1, api2, api4, api6, api7, breakpoints, dimensions, duration, location.pathname])

  return (
    <div className={`background ${theme}`}>
      <animated.img
        className="ball b1"
        src={ball1}
        alt="colored ball"
        style={{
          left: spring1.left,
          top: spring1.top,
          transform: spring4.transform,
          opacity: spring4.opacity,
        }}
      />
      <animated.img
        className="ball b2"
        src={ball2}
        alt="colored ball"
        style={{
          left: spring2.left,
          top: spring2.top,
          transform: spring4.transform,
          opacity: spring4.opacity,
        }}
      />
      {/* <animated.img
        className="ball b3"
        src={ball1}
        alt="colored ball"
        style={{
          left: spring3.left,
          top: spring3.top,
          transform: spring5.transform,
          opacity: spring5.opacity,
        }}
      /> */}
      <animated.img
        className="ball b4"
        src={ball3}
        alt="colored ball"
        style={{
          left: spring7.left,
          top: spring7.top,
          transform: spring6.transform,
          opacity: spring6.opacity,
        }}
      />
    </div>
  )
}

export default Background
