import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getPrevPage } from '../../utils/navUtils'

import './index.scss'

function LeftCaret() {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 11.5L2 6.5L7 1.5" stroke="white" strokeWidth="2" strokeLinecap="square"/>
    </svg>
  )
}

function LogoSvg() {
  return (
    <svg width="60" height="17" viewBox="0 0 60 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1379 15.8276C13.4897 15.8276 13.7586 15.7655 13.7586 15.2276V12.5172C13.7586 12.2069 13.6759 12.1241 13.3448 12.0827C12.5793 12 12.3103 10.8621 11.669 9.76551C12.6414 8.85516 13.2 7.61378 13.2 6.1862C13.2 3.33102 10.9655 1.15861 7.55172 1.15861H0.206897C0.0827586 1.15861 0 1.24137 0 1.36551V15.4345C0 15.5586 0.0827586 15.6414 0.206897 15.6414H3.72414C3.84828 15.6414 3.93103 15.5586 3.93103 15.4345V11.2138H6.93104C9.45517 11.2138 7.96552 15.8276 13.1379 15.8276ZM3.93103 7.48965V4.88275H7.55172C8.58621 4.88275 9.26897 5.27585 9.26897 6.1862C9.26897 7.09654 8.58621 7.48965 7.55172 7.48965H3.93103Z" fill="white"/>
      <path d="M22.6106 15.9724C27.4519 15.9724 30.6175 12.9724 30.6175 8.3793C30.6175 3.80689 27.4519 0.827576 22.6106 0.827576C17.7692 0.827576 14.583 3.80689 14.583 8.3793C14.583 12.9724 17.7692 15.9724 22.6106 15.9724ZM22.6106 12.2483C20.1278 12.2483 18.514 10.7172 18.514 8.3793C18.514 6.06206 20.1278 4.55171 22.6106 4.55171C25.0726 4.55171 26.6864 6.06206 26.6864 8.3793C26.6864 10.7172 25.0726 12.2483 22.6106 12.2483Z" fill="white"/>
      <path d="M38.5043 15.9724C43.1594 15.9724 45.9525 14.2345 45.9525 11.2965C45.9525 7.19999 42.4146 6.72413 39.187 6.53792C37.3456 6.43447 35.7939 6.41378 35.7939 5.66896C35.7939 4.82068 37.1801 4.55171 38.7112 4.55171C41.4629 4.55171 43.2215 5.06895 44.3594 5.85516C44.7525 6.12413 44.918 6.04137 44.918 5.48275V2.48275C44.918 2.13102 44.8353 2.00689 44.587 1.88275C43.387 1.28275 41.587 0.827576 38.7112 0.827576C34.5732 0.827576 31.8629 2.52413 31.8629 5.46206C31.8629 9.31033 34.8836 9.99309 37.5732 10.1379C40.118 10.2827 42.0215 10.1793 42.0215 11.0896C42.0215 11.9379 40.8215 12.2483 38.5043 12.2483C36.1249 12.2483 33.9525 11.6896 32.8146 10.9034C32.4215 10.6345 32.256 10.7172 32.256 11.2759V14.2759C32.256 14.6276 32.3387 14.7517 32.587 14.8759C33.787 15.4759 36.0008 15.9724 38.5043 15.9724Z" fill="white"/>
      <path d="M47.6121 15.6414H59.1983C59.4259 15.6414 59.6121 15.4552 59.6121 15.2276V12.331C59.6121 12.1034 59.4259 11.9172 59.1983 11.9172H51.1293V10.0552H57.5431C57.7707 10.0552 57.9569 9.86896 57.9569 9.64137V6.95171C57.9569 6.72413 57.7707 6.53792 57.5431 6.53792H51.1293V4.88275H59.1983C59.4259 4.88275 59.6121 4.69654 59.6121 4.46895V1.5724C59.6121 1.34482 59.4259 1.15861 59.1983 1.15861H47.6121C47.3845 1.15861 47.1983 1.34482 47.1983 1.5724V15.2276C47.1983 15.4552 47.3845 15.6414 47.6121 15.6414Z" fill="white"/>
    </svg>
  )
}

function ProjectsHeader() {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const handleBack = () => {
    if (params.projectSlug) {
      navigate('/projects')
    } else if (location.pathname === '/contact-us' || location.pathname === '/success') {
      navigate('/projects')
    } else {
      navigate(getPrevPage(location))
    }
  }

  return (
    <>
      <div className="back" onClick={handleBack}>
        <LeftCaret />
        Back
      </div>
      <div className="logo">
        <LogoSvg />
      </div>
    </>
  )
}

export default ProjectsHeader
