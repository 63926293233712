import { Suspense, useEffect, useRef, useState } from 'react'
import { useBreakpoints } from '../../utils/hooks'
import { Canvas, useFrame } from 'react-three-fiber'
import { Bounds, Center, useBounds, useGLTF } from '@react-three/drei'
import generateRandomRotation from '../../utils/generateRandomRotation'
import { Quaternion, Vector3 } from 'three'

import gltfRose from './assets/ROSE.gltf'
import gltf2022 from './assets/2022.gltf'
import './index.scss'

const ROSE_MOVEMENTS = [
  { x: -0.5, y: -0.5, z: 0 },
  { x: 0, y: -0.75, z: 0 },
  { x: .75, y: -0.5, z: 0 },
  { x: 1, y: 0, z: 0 },
  { x: 0, y: -0.25, z: 0 },
]

const YEAR_MOVEMENTS = [
  { x: 0.75, y: 0.25, z: 0 },
  { x: 0, y: 0.75, z: 0 },
  { x: -0.25, y: 0.25, z: 0 },
  { x: -0.75, y: 0.75, z: 0 },
  { x: 0.25, y: 1, z: 0 },
]

const MOVEMENT_SPEED = 0.005

function Model({ path, movement, ...otherProps }) {
  const [targetRotation, setTargetRotation] = useState(generateRandomRotation())
  const breakpoints = useBreakpoints()
  const loadedModel = useGLTF(path)
  const modelRef = useRef(null)
  const calcQuatRef = useRef(new Quaternion())

  useFrame(() => {
    calcQuatRef.current.setFromEuler(modelRef.current.rotation)

    const targetQuaternion = new Quaternion().setFromEuler(targetRotation)
    const angleDiff = calcQuatRef.current.angleTo(targetQuaternion)

    if (angleDiff > 0.001) {
      calcQuatRef.current.rotateTowards(targetQuaternion, 0.001)
      modelRef.current.rotation.setFromQuaternion(calcQuatRef.current)
    } else {
      modelRef.current.rotation.copy(targetRotation)
      setTargetRotation(generateRandomRotation())
    }
  })

  return (
    <primitive object={loadedModel.scene} scale={breakpoints.lg ? [1, 1, 1] : [0.5, 0.5, 0.5]} ref={modelRef} {...otherProps} />
  )
}

function ModelGroup() {
  const [roseMovementIndex, setRoseMovementIndex] = useState(0)
  const [yearMovementIndex, setYearMovementIndex] = useState(0)
  const bounds = useBounds()
  const roseRef = useRef(null)
  const roseMovementAxis = useRef(new Vector3())
  const roseTargetPosition = useRef(null)
  const yearRef = useRef(null)
  const yearMovementAxis = useRef(new Vector3())
  const yearTargetPosition = useRef(null)

  useEffect(() => {
    const boundingSize = bounds.getSize().size

    if (!roseTargetPosition.current) {
      roseRef.current.position.set(
        ROSE_MOVEMENTS[0].x * boundingSize.x,
        ROSE_MOVEMENTS[0].y * boundingSize.y,
        ROSE_MOVEMENTS[0].z * boundingSize.z,
      )
    }

    roseTargetPosition.current = new Vector3(
      ROSE_MOVEMENTS[roseMovementIndex].x * boundingSize.x,
      ROSE_MOVEMENTS[roseMovementIndex].y * boundingSize.y,
      ROSE_MOVEMENTS[roseMovementIndex].z * boundingSize.z,
    )

    roseMovementAxis.current.x = roseTargetPosition.current.x - roseRef.current.position.x
    roseMovementAxis.current.y = roseTargetPosition.current.y - roseRef.current.position.y
    roseMovementAxis.current.z = roseTargetPosition.current.z - roseRef.current.position.z
    roseMovementAxis.current.normalize()
  }, [bounds, roseMovementIndex])

  useEffect(() => {
    const boundingSize = bounds.getSize().size

    if (!yearTargetPosition.current) {
      yearRef.current.position.set(
        YEAR_MOVEMENTS[0].x * boundingSize.x,
        YEAR_MOVEMENTS[0].y * boundingSize.y,
        YEAR_MOVEMENTS[0].z * boundingSize.z,
      )
    }

    yearTargetPosition.current = new Vector3(
      YEAR_MOVEMENTS[yearMovementIndex].x * boundingSize.x,
      YEAR_MOVEMENTS[yearMovementIndex].y * boundingSize.y,
      YEAR_MOVEMENTS[yearMovementIndex].z * boundingSize.z,
    )

    yearMovementAxis.current.x = yearTargetPosition.current.x - yearRef.current.position.x
    yearMovementAxis.current.y = yearTargetPosition.current.y - yearRef.current.position.y
    yearMovementAxis.current.z = yearTargetPosition.current.z - yearRef.current.position.z
    yearMovementAxis.current.normalize()
  }, [bounds, yearMovementIndex])

  useFrame(() => {
    if (roseTargetPosition.current) {
      if (roseRef.current.position.distanceTo(roseTargetPosition.current) > 0.1) {
        roseRef.current.translateOnAxis(roseMovementAxis.current, MOVEMENT_SPEED)
      } else {
        setRoseMovementIndex((roseMovementIndex + 1) % ROSE_MOVEMENTS.length)
      }
    }

    if (yearTargetPosition.current) {
      if (yearRef.current.position.distanceTo(yearTargetPosition.current) > 0.1) {
        yearRef.current.translateOnAxis(yearMovementAxis.current, MOVEMENT_SPEED)
      } else {
        setYearMovementIndex((yearMovementIndex + 1) % YEAR_MOVEMENTS.length)
      }
    }
  })

  return (
    <group>
      <group ref={roseRef}>
        <Model path={gltfRose} />
      </group>
      <group ref={yearRef}>
        <Model path={gltf2022} />
      </group>
    </group>
  )
}

function Scene() {
  return (
    <div className="scene">
      <Canvas camera={{ fov: 50, position: [0, 0, 8] }}>
        <ambientLight intensity={0.3} />
        <directionalLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <Bounds>
            <Center>
              <ModelGroup />
            </Center>
          </Bounds>
        </Suspense>
      </Canvas>
    </div>
  )
}

export default Scene
