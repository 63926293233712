import { createContext, useContext } from "react"

export const THEMES = {
  dark: 'dark',
  light: 'light',
  darkBgDarkPill: 'darkBgDarkPill',
  lightBgLightPill: 'lightBgLightPill',
}

export const ThemeContext = createContext({
  theme: THEMES.dark,
  setTheme: () => {},
})

export function useTheme() {
  const themeObj = useContext(ThemeContext)

  return themeObj
}
