import { useSwipeNavigation } from '../../utils/hooks';
import ContactView from '../../views/Contact';
import ContentView from '../../views/Content';
import LandingView from '../../views/Landing';
import Page from '../Page';
import ProjectDetailView from '../../views/ProjectDetail';
import ProjectsView from '../../views/Projects';
import { Route, Routes } from 'react-router-dom'
import SuccessView from '../../views/Success';

function AppRouting() {
  useSwipeNavigation()

  return (
    <Routes>
      <Route path="/" element={<Page />}>
        <Route index element={<LandingView />} />
        <Route path=":categoryName" element={<ContentView />}>
          <Route index element={<ContentView />} />
          <Route path=":pageNumber" element={<ContentView />} />
        </Route>
        <Route path="projects">
          <Route index element={<ProjectsView />} />
          <Route path=":projectSlug" element={<ProjectDetailView />} />
        </Route>
        <Route path="contact-us" element={<ContactView />} />
        <Route path="success" element={<SuccessView />} />
      </Route>
    </Routes>
  )
}

export default AppRouting
