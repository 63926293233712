import { useNavigate } from "react-router-dom"
import Paragraph1 from "../Paragraph1"
import Paragraph2 from "../Paragraph2"

import './index.scss'

function ProjectList({ projects = [] }) {
  const navigate = useNavigate()

  const renderProjects = () => (
    projects.map((project, index) => {
      const handleClick = () => {
        navigate(`/projects/${project.slug}`)
      }

      return (
        <div className="project-item" key={project.name}>
          <div className="summary">
            <div className="number">
              {String(index).padStart(2, '0')}
            </div>
            <Paragraph1>
              {project.summary}
            </Paragraph1>
          </div>
          <button className="cta" onClick={handleClick}>
            <Paragraph2>
              Learn More
            </Paragraph2>
          </button>
        </div>
      )
    })
  )

  return (
    <div className="project-list">
      {renderProjects()}
    </div>
  )
}

export default ProjectList
