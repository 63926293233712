import { useEffect } from 'react';
import { animated, config, useSpring } from '@react-spring/web'
import { useLocation, useNavigate } from 'react-router';
import { THEMES, useTheme } from '../../utils/hooks';
import classNames from 'classnames'
import Heading from "../../components/Heading";
import Paragraph2 from "../../components/Paragraph2";
import Scene from '../../components/Scene';

import './index.scss'

function LandingView() {
  const location = useLocation()
  const navigate = useNavigate()
  const { setTheme } = useTheme()
  const [ spring, api ] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  useEffect(() => {
    if (location.pathname === '/') {
      if (location.state === 'prepare') {
        api.start({
          to: { opacity: 0 },
          config: config.slow,
          onRest() {
            navigate('/who-we-are/1')
          },
        })
      } else {
        api.start({
          to: { opacity: 1 },
          config: config.slow,
          delay: 150,
        })
      }
    }
    setTheme(THEMES.dark)
  }, [api, location, navigate, setTheme])
  
  return (
    <animated.div className={classNames('landing', { notouch: location.pathname !== '/' })} style={spring}>
      <div className="contents">
        <Scene />
        <Heading />
        <div className="text">
          <Paragraph2>
            We have had an incredible year pushing the boundaries of immersive technology. Here is some inspiration to take you into 2023.
          </Paragraph2>
        </div>
      </div>
    </animated.div>
  )
}

export default LandingView
