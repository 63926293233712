import { animated, config, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useTheme, THEMES, useBreakpoints } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

import video from './assets/1.mp4'

function WineAndSpirits({ position }) {
  const breakpoints = useBreakpoints()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { left: '0', top: '0' },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { left: '0', top: '0' },
  }))
  const [spring3, api3] = useSpring(() => ({
    from: { top: '0', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      if (breakpoints.lg) {
        api1.start({ left: '0', config: config.default })
        api2.start({ left: '0', config: config.default })
        api3.set({ top: '0' })
      } else {
        api1.start({ top: '0' })
        api2.start({ top: '0' })
      }
      api3.start({ opacity: 1, top: '0' })

      setTheme(breakpoints.lg ? THEMES.darkBgDarkPill : THEMES.dark)
    } else if (position === -1) {
      if (breakpoints.lg) {
        api1.start({ left: '100%', config: config.molasses })
        api2.start({ left: '-100%', config: config.molasses })
        api3.start({ opacity: 0 })
      } else {
        api1.set({ left: '0' })
        api2.set({ left: '0' })
        api1.start({ top: '-100%' })
        api2.start({ top: '100%' })
        api3.start({ opacity: 0 })
      }
    } else if (position === 1) {
      if (breakpoints.lg) {
        api1.set({ left: '100%' })
        api2.set({ left: '-100%' })
        api3.start({ opacity: 0, top: '100%' })
      } else {
        api1.set({ left: '0' })
        api2.set({ left: '0' })
        api1.start({ top: '-100%' })
        api2.start({ top: '100%' })
        api3.start({ opacity: 0 })
      }
    }
  }, [api1, api2, api3, breakpoints, position, setTheme])

  return (
    <animated.div className={classNames('wine-and-spirits', { notouch: position !== 0 })} style={spring3}>
      <animated.div className="media" style={spring2}>
        <video src={video} crossOrigin="anonymous" autoPlay loop muted playsInline disableRemotePlayback />
      </animated.div>
      <animated.div className="text" style={spring1}>
        <Paragraph1>
          We worked with <span className="blue">Moët Hennessy</span> to help customers decide on the perfect wine or spirit for their entertainment personality type, lowering indecision at the shelf.
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default WineAndSpirits
