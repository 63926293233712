import { animated, useSpring } from '@react-spring/web'
import { useEffect } from "react"
import { useLocation } from 'react-router'
import { useTheme, THEMES } from "../../../utils/hooks"
import classNames from 'classnames'
import Paragraph1 from '../../../components/Paragraph1'

function WhoWeAre4({ position }) {
  const location = useLocation()
  const { setTheme } = useTheme()
  const [spring1, api1] = useSpring(() => ({
    from: { opacity: 0 },
  }))
  const [spring2, api2] = useSpring(() => ({
    from: { top: '0', opacity: 0 },
  }))

  useEffect(() => {
    if (position === 0) {
      if (location.state === 'back') {
        api1.start({ top: '0', opacity: 1 })
        api2.start({ from: { top: '-100%' }, top: '0', opacity: 1, })
      } else {
        api1.start({
          to: { opacity: 1 },
          delay: 250,
        })
        api2.start({
          from: { top: '100%', opacity: 0, },
          to: { top: '0%', opacity: 1 },
        })
      }

      setTheme(THEMES.dark)
    } else if (position === 1) {
      api2.set({ top: '100%', opacity: 0 })
    } else if (position === -1) {
      api2.start({
        to: { top: '-100%', opacity: 0 },
      })
    }
  }, [api1, api2, location.state, position, setTheme])

  return (
    <animated.div className={classNames('who-we-are-4', { notouch: position !== 0 })} style={spring2}>
      <div className="gif-background"></div>
      <animated.div className="shade" style={spring1}></animated.div>
      <animated.div className="text" style={spring1}>
        <Paragraph1>
          Whoa, that was a lot of acronyms... But don't worry — we have examples!
        </Paragraph1>
      </animated.div>
    </animated.div>
  )
}

export default WhoWeAre4
